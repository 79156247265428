.questionlink {
  text-decoration: none;
}
.view-answer-section {
  margin: 20px 0;
  padding: 20px;
  background-color: #333;
  color: white;
  text-align: center;
  border-radius: 10px;
  position: relative;
}

.answer-box {
  position: relative;
  padding: 20px 40px;
  background-image: url("../../images/blur.png"),
    linear-gradient(rgba(74, 74, 74, 0.9), rgba(74, 74, 74, 0.9));
  background-blend-mode: overlay;
  background-size: cover;
  border-radius: 10px;
  color: white;
}

.boximg {
  height: 120px;
  width: auto;
}

.student-helped {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.view-answer-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #f50057;
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
}

.view-answer-button:hover {
  background-color: #ff4081;
}
