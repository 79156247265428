.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.btn-block {
  display: block;
  width: 100%;
}
.bttn {
  border-radius: 0;
}
.home-image {
  background-image: url("../public/assets/images/home.jpg"); 
  background-color: #cccccc; 
  height: auto; 
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
}
.homeoverlay{
  background: linear-gradient(180deg, #F7EDE7 0%, rgba(246, 229, 219, 0.26) 80%);;
}
.black{
  color: #000;
}
.white{
  color: white;
}
.green{
  color: green;
}
.convinced{
  background-color: green;
  /* padding: 60px 30px; */
}
.ft-12{
  font-size: 12px;
}
.bg-white{
  background-color: #ffffff;
}
.card-img-banner-wrapper {
  position: relative;
  display: block;
}
.card-img-banner-wrapper img{
  height: 180px;
  object-fit: contain;
}
.card-img-banner {
  position: absolute;
  display: block;
  bottom: 12px;
  right: -5px;
  box-shadow: 0px 0 2px 2px #3a3a3a26;
  border-radius: 2px;
  padding: 3px 10px 2px 10px;
  color: #fff;
  border: 2px solid #000;
  background-color: #000;
}

.homelist li{
      padding: 10px 20px !important;
    margin-bottom: 10px !important;
}

.text-small{
  font-size: 12px;
}
.react-international-phone-input-container .react-international-phone-input{
  width: 100%;
}
.thelink{
  text-decoration: none !important;
  cursor: pointer !important;
  color: #331D74;
}

.sb-sticky-top {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 100px; /* Adjust this value as needed */
}

.nodecoration{
  text-decoration: none;
}