.box .steps {
  border-bottom: 1px solid #ddd;
}
.box .steps ul {
  overflow: hidden;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.box .steps ul li {
  flex: 1; 
}

.box .steps ul li div {
  color: #999;
  padding: 10px;
  position: relative;
  background: #f5f5f5;
  width: 100%;
  text-align: center;
}
.box .steps ul li div span {
  font-size: 13px;
}
.box .steps ul li:first-child div {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.box .steps ul li:last-child div {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.box .steps ul li div::before {
  content: " ";
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #ddd;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 1;
  margin-left: 1px;
}
.box .steps ul li div::after {
  content: " ";
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 30px solid #f5f5f5;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 100%;
  z-index: 2;
}
.box .steps ul li.done div {
  border-color: #70ddb4 !important;
  color: #fff !important;
  background: #70ddb4 !important;
}
.box .steps ul li.done div::after {
  border-left: 30px solid #70ddb4;
}
.box .steps ul li.active div {
  border-color: #16b479 !important;
  color: #fff !important;
  background: #16b479 !important;
}
.box .steps ul li.active div::after {
  border-left: 30px solid #16b479;
}

.box .step-component {
  padding: 20px;
  height: auto;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}
.box .btn-component {
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .box .steps ul {
    flex-direction: column;
    margin-bottom: 0;
  }
  .box .steps ul li div {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .box .btn-component {
    flex-direction: column;
  }
}
