.orderitem{
    text-decoration: none;
}
.orderitem .card-body:hover{
    background-color: #20245d17;
}
.files input {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 40px 0px 50px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
}

