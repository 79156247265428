.blog-body {
  min-height: 150px; /* Adjust the height as needed */
  overflow: hidden; /* Hide overflow content if it exceeds the height */
  
}
.btn-readmore{
    bottom: 0 !important;
}
.blogitem-img{
    height: 400px;
    width: 100%;
    object-fit: cover;
}
.blogauthoritem-img{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
}
.mr-2{
    margin-right: 20px;
}
.mr-2{
    margin-right: 10px;
}
.blogimg{
    height: 200px;
    width: 100%;
    object-fit: cover;
}