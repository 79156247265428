
.chatbox {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border: 1px solid #d2d6de;
    margin-bottom: 20px;
    width: 100%;
}

.chatbox.chatbox-primary {
    /* border-top-color: ; */
}

.chatbox.chatbox-info {
    border-top-color: #00c0ef;
}

.chatbox.chatbox-danger {
    border-top-color: #dd4b39;
}

.chatbox.chatbox-warning {
    border-top-color: #f39c12;
}

.chatbox.chatbox-success {
    border-top-color: #00a65a;
}

.chatbox.chatbox-default {
    border-top-color: #d2d6de;
}

.chatbox.collapsed-chatbox .chatbox-body,
.chatbox.collapsed-chatbox .chatbox-footer {
    display: none;
}

.chatbox .nav-stacked>li {
    border-bottom: 1px solid #f4f4f4;
    margin: 0;
}

.chatbox .nav-stacked>li:last-of-type {
    border-bottom: none;
}

.chatbox.height-control .chatbox-body {
    max-height: 300px;
    overflow: auto;
}

.chatbox .border-right {
    border-right: 1px solid #f4f4f4;
}

.chatbox .border-left {
    border-left: 1px solid #f4f4f4;
}

.chatbox.chatbox-solid {
    border-top: 0;
}

.chatbox.chatbox-solid>.chatbox-header .btn.btn-default {
    background: transparent;
}

.chatbox.chatbox-solid>.chatbox-header .btn:hover,
.chatbox.chatbox-solid>.chatbox-header a:hover {
    background: rgba(0, 0, 0, 0.1);
}

.chatbox.chatbox-solid.chatbox-default {
    border: 1px solid #d2d6de;
}

.chatbox.chatbox-solid.chatbox-default>.chatbox-header {
    color: #444;
    background: #d2d6de;
    background-color: #d2d6de;
}

.chatbox.chatbox-solid.chatbox-default>.chatbox-header a,
.chatbox.chatbox-solid.chatbox-default>.chatbox-header .btn {
    color: #444;
}

.chatbox.chatbox-solid.chatbox-primary {
    border: 1px solid;
}

.chatbox.chatbox-solid.chatbox-primary>.chatbox-header {
    color: #fff;
    background: #007bff;
    background-color: #007bff;
}

.chatbox.chatbox-solid.chatbox-primary>.chatbox-header a,
.chatbox.chatbox-solid.chatbox-primary>.chatbox-header .btn {
    color: #fff;
}

.chatbox.chatbox-solid.chatbox-info {
    border: 1px solid #00c0ef;
}

.chatbox.chatbox-solid.chatbox-info>.chatbox-header {
    color: #fff;
    background: #00c0ef;
    background-color: #00c0ef;
}

.chatbox.chatbox-solid.chatbox-info>.chatbox-header a,
.chatbox.chatbox-solid.chatbox-info>.chatbox-header .btn {
    color: #fff;
}

.chatbox.chatbox-solid.chatbox-danger {
    border: 1px solid #dd4b39;
}

.chatbox.chatbox-solid.chatbox-danger>.chatbox-header {
    color: #fff;
    background: #dd4b39;
    background-color: #dd4b39;
}

.chatbox.chatbox-solid.chatbox-danger>.chatbox-header a,
.chatbox.chatbox-solid.chatbox-danger>.chatbox-header .btn {
    color: #fff;
}

.chatbox.chatbox-solid.chatbox-warning {
    border: 1px solid #f39c12;
}

.chatbox.chatbox-solid.chatbox-warning>.chatbox-header {
    color: #fff;
    background: #f39c12;
    background-color: #f39c12;
}

.chatbox.chatbox-solid.chatbox-warning>.chatbox-header a,
.chatbox.chatbox-solid.chatbox-warning>.chatbox-header .btn {
    color: #fff;
}

.chatbox.chatbox-solid.chatbox-success {
    border: 1px solid #00a65a;
}

.chatbox.chatbox-solid.chatbox-success>.chatbox-header {
    color: #fff;
    background: #00a65a;
    background-color: #00a65a;
}

.chatbox.chatbox-solid.chatbox-success>.chatbox-header a,
.chatbox.chatbox-solid.chatbox-success>.chatbox-header .btn {
    color: #fff;
}

.chatbox.chatbox-solid>.chatbox-header>.chatbox-tools .btn {
    border: 0;
    chatbox-shadow: none;
}

.chatbox.chatbox-solid[class*='bg']>.chatbox-header {
    color: #fff;
}

.chatbox .chatbox-group>.chatbox {
    margin-bottom: 5px;
}

.chatbox .knob-label {
    text-align: center;
    color: #333;
    font-weight: 100;
    font-size: 12px;
    margin-bottom: 0.3em;
}

.chatbox>.overlay,
.overlay-wrapper>.overlay,
.chatbox>.loading-img,
.overlay-wrapper>.loading-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.chatbox .overlay,
.overlay-wrapper .overlay {
    z-index: 50;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 3px;
}

.chatbox .overlay>.fa,
.overlay-wrapper .overlay>.fa {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    color: #000;
    font-size: 30px;
}

.chatbox .overlay.dark,
.overlay-wrapper .overlay.dark {
    background: rgba(0, 0, 0, 0.5);
}

.chatbox-header:before,
.chatbox-body:before,
.chatbox-footer:before,
.chatbox-header:after,
.chatbox-body:after,
.chatbox-footer:after {
    content: " ";
    display: table;
}

.chatbox-header:after,
.chatbox-body:after,
.chatbox-footer:after {
    clear: both;
}

.chatbox-header {
    color: #444;
    display: block;
    padding: 10px;
    position: relative;
}

.chatbox-header.with-border {
    border-bottom: 1px solid #f4f4f4;
}

.collapsed-chatbox .chatbox-header.with-border {
    border-bottom: none;
}

.chatbox-header>.fa,
.chatbox-header>.glyphicon,
.chatbox-header>.ion,
.chatbox-header .chatbox-title {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    line-height: 1;
}

.chatbox-header>.fa,
.chatbox-header>.glyphicon,
.chatbox-header>.ion {
    margin-right: 5px;
}

.chatbox-header>.chatbox-tools {
    position: absolute;
    right: 10px;
    top: 5px;
}

.chatbox-header>.chatbox-tools [data-toggle="tooltip"] {
    position: relative;
}

.chatbox-header>.chatbox-tools.pull-right .dropdown-menu {
    right: 0;
    left: auto;
}

.btn-chatbox-tool {
    padding: 5px;
    font-size: 12px;
    background: transparent;
    color: #97a0b3;
}

.open .btn-chatbox-tool,
.btn-chatbox-tool:hover {
    color: #606c84;
}

.btn-chatbox-tool.btn:active {
    chatbox-shadow: none;
}

.chatbox-body {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 10px;
}

.no-header .chatbox-body {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.chatbox-body>.table {
    margin-bottom: 0;
}

.chatbox-body .fc {
    margin-top: 5px;
}

.chatbox-body .full-width-chart {
    margin: -19px;
}

.chatbox-body.no-padding .full-width-chart {
    margin: -9px;
}

.chatbox-body .chatbox-pane {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 3px;
}

.chatbox-body .chatbox-pane-right {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 0;
}

.chatbox-footer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top: 1px solid #f4f4f4;
    padding: 10px;
    background-color: #fff;
}

.direct-chat .chatbox-body {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    position: relative;
    overflow-x: hidden;
    padding: 0;
}

.direct-chat.chat-pane-open .direct-chat-contacts {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

.direct-chat-messages {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    padding: 10px;
    height: 250px;
    overflow: auto;
}

.direct-chat-msg,
.direct-chat-text {
    display: block;
}

.direct-chat-msg {
    margin-bottom: 10px;
}

.direct-chat-msg:before,
.direct-chat-msg:after {
    content: " ";
    display: table;
}

.direct-chat-msg:after {
    clear: both;
}

.direct-chat-messages,
.direct-chat-contacts {
    -webkit-transition: -webkit-transform .5s ease-in-out;
    -moz-transition: -moz-transform .5s ease-in-out;
    -o-transition: -o-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
}

.direct-chat-text {
    border-radius: 5px;
    position: relative;
    padding: 5px 10px;
    background: #d2d6de;
    border: 1px solid #d2d6de;
    margin: 5px 0 0 50px;
    color: #444;
}

.direct-chat-text:after,
.direct-chat-text:before {
    position: absolute;
    right: 100%;
    top: 15px;
    border: solid transparent;
    border-right-color: #d2d6de;
    content: ' ';
    height: 0;
    width: 0;
    pointer-events: none;
}

.direct-chat-text:after {
    border-width: 5px;
    margin-top: -5px;
}

.direct-chat-text:before {
    border-width: 6px;
    margin-top: -6px;
}

.right .direct-chat-text {
    margin-right: 50px;
    margin-left: 0;
}

.right .direct-chat-text:after,
.right .direct-chat-text:before {
    right: auto;
    left: 100%;
    border-right-color: transparent;
    border-left-color: #d2d6de;
}

.direct-chat-img {
    border-radius: 50%;
    float: left;
    width: 40px;
    height: 40px;
}

.right .direct-chat-img {
    float: right;
}

.direct-chat-info {
    display: block;
    margin-bottom: 2px;
    font-size: 12px;
}

.direct-chat-name {
    font-weight: 600;
}

.direct-chat-timestamp {
    color: #999;
}

.direct-chat-contacts-open .direct-chat-contacts {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

.direct-chat-contacts {
    -webkit-transform: translate(101%, 0);
    -ms-transform: translate(101%, 0);
    -o-transform: translate(101%, 0);
    transform: translate(101%, 0);
    position: absolute;
    top: 0;
    bottom: 0;
    height: 250px;
    width: 100%;
    background: #222d32;
    color: #fff;
    overflow: auto;
}

.contacts-list>li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin: 0;
}

.contacts-list>li:before,
.contacts-list>li:after {
    content: " ";
    display: table;
}

.contacts-list>li:after {
    clear: both;
}

.contacts-list>li:last-of-type {
    border-bottom: none;
}

.contacts-list-img {
    border-radius: 50%;
    width: 40px;
    float: left;
}

.contacts-list-info {
    margin-left: 45px;
    color: #fff;
}

.contacts-list-name,
.contacts-list-status {
    display: block;
}

.contacts-list-name {
    font-weight: 600;
}

.contacts-list-status {
    font-size: 12px;
}

.contacts-list-date {
    color: #aaa;
    font-weight: normal;
}

.contacts-list-msg {
    color: #999;
}

.direct-chat-danger .right>.direct-chat-text {
    background: #dd4b39;
    border-color: #dd4b39;
    color: #fff;
}

.direct-chat-danger .right>.direct-chat-text:after,
.direct-chat-danger .right>.direct-chat-text:before {
    border-left-color: #dd4b39;
}

.direct-chat-primary .right>.direct-chat-text {
    background: #007bff;
    border-color: #007bff;
    color: #fff;
}

.direct-chat-primary .right>.direct-chat-text:after,
.direct-chat-primary .right>.direct-chat-text:before {
    border-left-color: #007bff;
}

.direct-chat-warning .right>.direct-chat-text {
    background: #f39c12;
    border-color: #f39c12;
    color: #fff;
}

.direct-chat-warning .right>.direct-chat-text:after,
.direct-chat-warning .right>.direct-chat-text:before {
    border-left-color: #f39c12;
}

.direct-chat-info .right>.direct-chat-text {
    background: #00c0ef;
    border-color: #00c0ef;
    color: #fff;
}

.direct-chat-info .right>.direct-chat-text:after,
.direct-chat-info .right>.direct-chat-text:before {
    border-left-color: #00c0ef;
}

.direct-chat-success .right>.direct-chat-text {
    background: #00a65a;
    border-color: #00a65a;
    color: #fff;
}

.direct-chat-success .right>.direct-chat-text:after,
.direct-chat-success .right>.direct-chat-text:before {
    border-left-color: #00a65a;
}


